import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Typography, IconButton, Checkbox } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { addNewDeviceFamily, getSpecificDeviceFamily, modifyDeviceFamily } from 'api/device_family';

interface EntrySchema {
  point_name: string;
  shown_name: string;
  measured_values: string;
  description: string;
  is_active_for_contractor: boolean;
}

interface DeviceFamily {
  device_name: string;
  data_schema: EntrySchema[];
  description: string;
}

interface AddDeviceFamilyFormProps {
  device_family_id: number;
  onSuccess: () => void;
}

const ModifyDeviceFamilyForm: React.FC<AddDeviceFamilyFormProps> = ({ device_family_id, onSuccess }) => {
  const [deviceName, setDeviceName] = useState<string>('');
  const [deviceDescription, setDeviceDescription] = useState<string>('');
  const [entries, setEntries] = useState<EntrySchema[]>([
    { point_name: '', measured_values: '', description: '', shown_name: '', is_active_for_contractor: true },
  ]);

  const fetchData = async () => {
    try {
      const response = await getSpecificDeviceFamily(device_family_id);
      setDeviceName(response.device_name);
      setDeviceDescription(response.description);
      setEntries(response.data_schema);
    } catch (error) {
      console.error('Error fetching device family:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddEntry = () => {
    setEntries([...entries, { point_name: '', measured_values: '', description: '', shown_name: '', is_active_for_contractor: true }]);
  };

  const handleRemoveEntry = (index: number) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleChangeEntry = (index: number, field: keyof EntrySchema, value: string | boolean) => {
    const newEntries = [...entries];
    if (field === 'is_active_for_contractor') {
      newEntries[index][field] = value as boolean; // Safe cast
    } else {
      newEntries[index][field] = value as string; // Safe cast
    }

    setEntries(newEntries);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const deviceFamily: DeviceFamily = {
      device_name: deviceName,
      description: deviceDescription,
      data_schema: entries,
    };
    const result = await modifyDeviceFamily(device_family_id, deviceFamily);
    if (result) {
      await onSuccess();
      console.log('Device Family modified successfully');
    } else {
      console.log('Failed to modify Device Family');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <TextField
        label="Имя семейства"
        fullWidth
        value={deviceName}
        onChange={(e) => setDeviceName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Описание"
        fullWidth
        value={deviceDescription}
        onChange={(e) => setDeviceDescription(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      {entries.map((entry, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={entry.is_active_for_contractor}
            onChange={(e) => handleChangeEntry(index, 'is_active_for_contractor', e.target.checked)}
          />
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
          <Typography variant="body2">Показывать</Typography>
          <Typography variant="body2">контракторам</Typography>
        </Box>
        </Box>
          <TextField
            label="Программное Имя (Латынь)"
            value={entry.point_name}
            onChange={(e) => handleChangeEntry(index, 'point_name', e.target.value)}
            required
            sx={{ flex: 1, mr: 2 }}
          />
          <TextField
            label="Отображаемое Имя"
            value={entry.shown_name}
            onChange={(e) => handleChangeEntry(index, 'shown_name', e.target.value)}
            required
            sx={{ flex: 1, mr: 2 }}
          />
          <TextField
            label="Измеряемые значения"
            value={entry.measured_values}
            onChange={(e) => handleChangeEntry(index, 'measured_values', e.target.value)}
            required
            sx={{ flex: 1, mr: 2 }}
          />
          <TextField
            label="Описание"
            value={entry.description}
            onChange={(e) => handleChangeEntry(index, 'description', e.target.value)}
            required
            sx={{ flex: 4, mr: 2 }}
          />
          <IconButton onClick={() => handleRemoveEntry(index)} color="error">
            <Remove />
          </IconButton>
        </Box>
      ))}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2, marginBottom: 3 }}>
        <Button variant="contained" color="primary" onClick={handleAddEntry} startIcon={<Add />}>
          Добавить значение
        </Button>
        <Button type="submit" variant="contained" color="secondary" sx={{ mt: 2 }}>
          Изменить семейство
        </Button>
      </Box>
    </Box>
  );
};

export default ModifyDeviceFamilyForm;