import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Plot from "react-plotly.js";
import { Data } from 'plotly.js';
import { getDeviceValues } from 'api/retrieve_values';
import { MetricsPlot } from './DataPlotPlotly';
import { Divider, Alert } from "@mui/material"; 
import { DatePicker } from '@mui/x-date-pickers';
import { Typography, Box, Grid } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import CircularProgress from '@mui/material/CircularProgress';


interface PlotData {
  measured_value: string;
  timestamps: string[][];
  values: number[][];
  point_names: string[];
  shown_names: string[];
}

interface DeviceMetricsPlotsProps {
  device_id: string;
}

interface LastValuesPerMeasuredValue {
  timestamp: string;
  value: number;
  shown_name: string;
}

function getLastValues(data: PlotData[]) {
  const dictionary: { [id: string]: { [id: string]: LastValuesPerMeasuredValue } } = {};

  data.forEach(item => {
    const measuredValue = item.measured_value;
    const pointNames = item.point_names;
    const timestamps = item.timestamps;
    const values = item.values;
    const shownNames = item.shown_names;

    const pointDictionary: { [id: string]: LastValuesPerMeasuredValue } = {};
    pointNames.forEach((point, index) => {
      const timestamp = timestamps[index][0];
      const value = values[index][0];
      const shown_name = shownNames[index];
      pointDictionary[point] = {
        timestamp: timestamp,
        value: value,
        shown_name: shown_name,
      };
    });

    dictionary[measuredValue] = pointDictionary;
  });

  return dictionary;
}

export const DeviceMetricsPlots: React.FC<DeviceMetricsPlotsProps> = ({ device_id }) => {
  const [dataForPlot, setPlotData] = useState<PlotData[] | null>(null);
  const [lastValues, setLastValues] = useState<{ [id: string]: { [id: string]: LastValuesPerMeasuredValue } }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Error state

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const [startDate, setStartDate] = useState<Date | null>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(currentDate);

  const fetchData = async () => {
    setLoading(true);
    setError(null); // Reset error state
    try {
      if (startDate && endDate) {
        const data = await getDeviceValues(device_id, startDate, endDate);
        setPlotData(data);
        const last_values = getLastValues(data);
        setLastValues(last_values);
      }
    } catch (error) {
      setError('Ошибка при загрузке данных. Пожалуйста, попробуйте позже.'); // Set error message
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 1000000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  if (loading) {
    return <CircularProgress />;
  }

  const timeZone = 'Europe/Minsk';

  return (
    <div>
      <Box sx={{ flexDirection: 'row' }} marginBottom="1rem" display="flex" alignItems="center">
        <DatePicker
          label="Начальная дата"
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          sx={{ marginRight: '1rem' }}
        />
        <DatePicker 
          label="Конечная дата" 
          value={endDate} 
          onChange={(newValue) => {
            setEndDate(newValue);
          }} 
        />
        {loading && <CircularProgress size={24} sx={{ marginLeft: 1 }} />}
      </Box>

      {error && <Alert severity="error">{error}</Alert>} {/* Show error alert */}

      {dataForPlot && dataForPlot.length === 0 ? (
        <Alert severity="info">
          Данные за выбранный период отсутствуют
        </Alert>
      ) : (
        <Box mb={5}>
          {Object.entries(lastValues).map(([block, values]) => (
            <Box
              key={block}
              sx={{ marginBottom: 4, padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa' }}
            >
              <Box display="flex" alignItems="center" pb={2} mb={2} borderBottom="1px solid #ddd">
                <BubbleChartIcon color="primary" />
                <Typography variant="h6" component="h2" ml={2} fontWeight="bold">
                  {block}
                </Typography>
              </Box>
              <Grid container spacing={3}>
                {Object.entries(values).map(([key, { timestamp, value, shown_name }]) => {
                  const minutesSinceTimestamp = Math.floor((Date.now() - new Date(timestamp).getTime()) / 60000);
                  return (
                    <Grid item xs={12} md={6} lg={4} key={key}>
                      <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 1, backgroundColor: '#fff' }}>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                          Метрика
                        </Typography>
                        <Typography variant="body1" fontWeight="medium" gutterBottom>
                          {shown_name}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom mt={2}>
                          Программное имя
                        </Typography>
                        <Typography variant="body1" fontWeight="medium" gutterBottom>
                          {key}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom mt={2}>
                          Значение
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ color: 'primary.main', fontFamily: 'Monospace', fontSize: '1rem' }}
                          gutterBottom
                        >
                          {value}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom mt={2}>
                          Время показания
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {new Date(timestamp).toLocaleString('default', { timeZone })} 
                          <Typography variant="subtitle2" color="textSecondary">({minutesSinceTimestamp} минут назад)</Typography>
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ))}
        </Box>
      )}

      {dataForPlot && dataForPlot.length > 0 && (
        <>
          <Box display="flex" alignItems="center" pb={1} mb={3}>
            <StackedLineChartIcon color="primary" />
            <Typography variant="h5" component="h2" ml={1}>
              Все значения
            </Typography>
          </Box>

          {dataForPlot.map((plotData, index) => (
            <div key={index}>
              <MetricsPlot plot_data={plotData} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};