import * as React from 'react';
import { useState, useCallback } from 'react';
import Plot from "react-plotly.js";
import { Data, Layout } from 'plotly.js';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InputLabel from '@mui/material/InputLabel';

interface PlotData {
  measured_value: string;
  timestamps: string[][];
  values: number[][];
  point_names: string[];
  shown_names: string[];
}

interface PlotDataProps {
  plot_data: PlotData;
}



const movingAverage = (data: number[], smoothness: number): number[] => {
  if (smoothness <= 1) return data;
  const smoothed = [];
  for (let i = 0; i < data.length; i++) {
    const start = Math.max(0, i - Math.floor(smoothness / 2));
    const end = Math.min(data.length, i + Math.ceil(smoothness / 2));
    const subset = data.slice(start, end);
    const average = subset.reduce((a, b) => a + b, 0) / subset.length;
    smoothed.push(average);
  }
  return smoothed;
};

const gaussianSmoothing = (data: number[], smoothness: number): number[] => {
  if (smoothness <= 1) return data;

  const kernel = createGaussianKernel(smoothness);
  const half = Math.floor(kernel.length / 2);
  const smoothed = [];

  for (let i = 0; i < data.length; i++) {
    let sum = 0;
    let weightSum = 0;
    for (let j = 0; j < kernel.length; j++) {
      const index = i + j - half;
      if (index >= 0 && index < data.length) {
        sum += data[index] * kernel[j];
        weightSum += kernel[j];
      }
    }
    smoothed.push(sum / weightSum);
  }
  return smoothed;
};

const createGaussianKernel = (size: number): number[] => {
  const sigma = size / 6; // Standard deviation
  const kernel = [];
  let sum = 0;
  for (let i = 0; i < size; i++) {
    const x = i - Math.floor(size / 2);
    const value = Math.exp(-0.5 * (x * x) / (sigma * sigma));
    kernel.push(value);
    sum += value;
  }
  return kernel.map(v => v / sum);
};

export const MetricsPlot: React.FC<PlotDataProps> = ({ plot_data }) => {
  const [smoothness, setSmoothness] = useState(1);
  const [smoothingMethod, setSmoothingMethod] = useState('None');
  const [layout, setLayout] = useState<Partial<Layout>>({
    xaxis: { title: "Время" },
    yaxis: { title: plot_data.measured_value },
    colorway: [
      '#5C6BC0', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'
    ],
  });

  const data_for_plot: Data[] = [];

  for (let i = 0; i < plot_data.point_names.length; i++) {
    const timestamps = plot_data.timestamps[i].map(item => new Date(item));

    let smoothedY = plot_data.values[i];
    if (smoothingMethod === 'Moving Average') {
      smoothedY = movingAverage(plot_data.values[i], smoothness);
    } else if (smoothingMethod === 'Gaussian') {
      smoothedY = gaussianSmoothing(plot_data.values[i], smoothness);
    }

    data_for_plot.push({
      x: timestamps,
      y: smoothedY,
      name: plot_data.shown_names[i],
      mode: "lines",
      type: "scatter"
    });
  }


  const handleRelayout = useCallback((newLayout: Partial<Layout>) => {
    setLayout(prevLayout => ({
      ...prevLayout,
      xaxis: {
        ...prevLayout.xaxis,
        range: newLayout['xaxis.range'] || prevLayout.xaxis?.range,
      },
      yaxis: {
        ...prevLayout.yaxis,
        range: newLayout['yaxis.range'] || prevLayout.yaxis?.range,
      },
    }));
  }, []);

  return (
    <>
      <Box sx={{marginBottom: 4, padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa'}}>
      <Box display="flex" alignItems="center" pb={2} mb={2} borderBottom="1px solid #ddd">
      <BubbleChartIcon color="primary" />
          <Typography variant="h6" component="h2" ml={2} fontWeight="bold">
                {plot_data.measured_value}
          </Typography>
          
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 2, marginBottom: '10px', border: '1px solid #ddd', borderRadius: 1, backgroundColor: '#fff' }}>
      <Typography variant="body1" color="textPrimary" fontWeight="medium" sx={{marginBottom: 4}}>
        Настройки
      </Typography>
      <Box sx={{ width: 300, marginLeft: '10px', marginBottom:'20px', textAlign: 'left' }}>
        <FormControl fullWidth>
          <InputLabel id="smoothing-method-label">Метод сглаживания</InputLabel>
          <Select
            labelId="smoothing-method-label"
            id="smoothing-method"
            value={smoothingMethod}
            label="Smoothing Method"
            onChange={(event) => setSmoothingMethod(event.target.value)}
          >
            <MenuItem value="None">Не применять</MenuItem>
            <MenuItem value="Moving Average">Бегущее среднее</MenuItem>
            <MenuItem value="Gaussian">Гауссово</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: 300, marginLeft: '25px', marginBottom:'20px', textAlign: 'left' }}>
        <Typography id="smoothness-slider" gutterBottom>
          Сила сглаживания
        </Typography>
        <Slider
          aria-labelledby="smoothness-slider"
          min={1}
          max={100}
          step={1}
          value={smoothness}
          onChange={(event, value) => setSmoothness(value as number)}
          valueLabelDisplay="auto"
        />
      </Box>
      </Box>
      <Plot
        data={data_for_plot}
        useResizeHandler={true}
        style={{ width: "100%", height: 600 }}
        layout={{
          ...layout,
          yaxis: { ...layout.yaxis, title: plot_data.measured_value },
        }}
        onRelayout={handleRelayout}
      />
      </Box>
    </>
  );
};